import React from "react";
import TeamLeader from "./component/TeamLeader";
import TeamMember from "./component/TeamMember";
import { useTranslation } from "react-i18next";

export default function Index(props) {
  const { t } = useTranslation();
  return (
    <div>
      {/* <!--Breadcrumb Area--> */}
      <section className="breadcrumb-area banner-1">
        <div className="text-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 v-center">
                <div className="bread-inner">
                  <div className="bread-menu">
                    <ul>
                      <li>
                        <a href="/">{t("Home")}</a>
                      </li>
                      <li>
                        <a href="#/">{t("OurTeam")}</a>
                      </li>
                    </ul>
                  </div>
                  <div className="bread-title">
                    <h2>{t("OurTeamContent.Title")}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End Breadcrumb Area--> */}
      <TeamLeader />
      <TeamMember />
    </div>
  );
}
