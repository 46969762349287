import React from "react";
import { useTranslation } from "react-i18next";

export default function Location(props) {
  const { t } = useTranslation();
  return (
    <section className="our-office pad-tb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="common-heading">
              <span>{t("HomeContent.Location.Title")}</span>
              <h2>{t("HomeContent.Location.Description")}</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center upset shape-numm">
          <div
            className="col-lg-4 col-sm-6 shape-loc wow fadeInUp"
            data-wow-delay=".2s"
          >
            <div className="office-card">
              <div
                className="skyline-img"
                data-tilt
                data-tilt-max="4"
                data-tilt-speed="1000"
              >
                <img
                  src="https://res.cloudinary.com/deafomwc7/image/upload/v1664837475/codespace/images/location/codespace-tech-valley_pdpplp.jpg"
                  alt="old office"
                  className="img-fluid"
                />
              </div>
              <div className="office-text">
                <h4>Our Office:</h4>
                <p>
                  PERUM BUMI TUNGGULWULUNG INDAH NO. E-1, Kel. Tunggulwulung,
                  Kec. Lowokwaru, Kota Malang, Prov. Jawa Timur
                </p>
              </div>
            </div>
          </div>
          {/* <div
            className="col-lg-4 col-sm-6 shape-loc wow fadeInUp"
            data-wow-delay=".2s"
          >
            <div className="office-card">
              <div
                className="skyline-img"
                data-tilt
                data-tilt-max="4"
                data-tilt-speed="1000"
              >
                <img
                  src="https://res.cloudinary.com/deafomwc7/image/upload/v1710231195/codespace/images/location/codespace-tech-valley_pdpplp_drjz8f.webp"
                  alt="malang"
                  className="img-fluid"
                />
              </div>
              <div className="office-text">
                <h4>To New Office:</h4>
                <p>
                  Jl Angklung H1 no 1, Kel. Tunggulwulung, Kec. Lowokwaru, Kota
                  Malang, Jawa Timur 65141.
                </p>
              </div>
            </div>
          </div> */}
          {/* <div
            className="col-lg-4 col-sm-6 shape-loc wow fadeInUp"
            data-wow-delay=".5s"
          ></div> */}
        </div>
      </div>
    </section>
  );
}
