import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
const resources = {
  id: {
    translation: {
      Flag: "🇮🇩",
      FlagWithTitle: "🇮🇩 Translate",
      ToOther: "To 🇬🇧",
      Home: "Beranda",
      AboutUs: "Tentang Kami",
      OurTeam: "Tim Kami",
      Contact: "Hubungi Kami",
      SendUsMessage: "Kirim Pesan",
      HomeContent: {
        // section 1
        Affordable: "Terjangkau",
        LatestTech: "Terbaru",
        BestService: "Layanan Terbaik",
        UIUXOptmization: "Fokus UI/UX",
        ShortVision: "Jadikan bisnis anda naik kelas",
        HashtagVision: "#BisnisNaikKelas #CodespaceIndonesia 🧑🏻‍🚀",
        LatestWorks: "Project Terakhir Kami",
        // section 2
        WeAreSolution: "Kami Adalah Solusi",
        Friends: "Teman",
        ForBuild: "Untuk Membangun Aplikasi",
        WeProvide: "Kami menawarkan",
        WeProvide2: "harga kompetitif",
        WeProvide3:
          "untuk mewujudkan digitalisasi bisnis anda, disisi lain kami mencoba untuk memaksimalkan",
        WeProvide4: "teknologi terbaru",
        WeProvide5: "disetiap proyek yang kami kerjakan dengan",
        WeProvide6: "optimisasi desain tampilan dan pengalaman pengguna",
        WeProvide7: "dengan",
        WeProvide8: "layanan terbaik",
        WeProvide9: "yang berfokus pada kepuasan klien",
        Services: {
          CompanyProfile: {
            Title: "Web Perusahaan",
            Description: `Layanan profil web cocok bagi Anda yang menginginkan web sederhana tanpa kustomisasi rumit untuk menampilkan informasi pribadi dan perusahaan.`,
          },
          WebApplication: {
            Title: "Aplikasi Web",
            Description: `Layanan ini sangat cocok bagi Anda yang membutuhkan fitur khusus pada sebuah website. Jangan khawatir, kami menggunakan teknologi terkini dalam setiap pengembangan.`,
          },
          MobileApplication: {
            Title: "Aplikasi Mobile",
            Description: `Pilihan ini sangat cocok bagi Anda yang ingin aplikasi Anda dapat diakses secara mobile dengan menggunakan fungsi-fungsi yang ada pada perangkat mobile.`,
          },
          ITConsultant: {
            Title: "Konsultan IT",
            Description: `Diskusikan dengan kami segala sesuatu yang ada di kepala Anda mengenai ide, tujuan, dan aspirasi bisnis Anda di masa depan`,
            Button: "Hubungi kami",
          },
          Maintenance: {
            Title: "Layanan Pemeliharaan",
            Description: `Kami menyediakan pemeliharaan atau penambahan fitur minor baru bila diperlukan`,
            OptionOne: "PURNA JUAL",
            OptionTwo: "VENDOR LAIN",
          },
          DigitalMarketing: {
            Title: "Pemasaran Daring",
            Description: `Codespace sedang mempersiapkan sesuatu yang spesial untuk astro people...`,
            OptionOne: "SEGERA",
          },
        },
        WhyChooseUs: {
          Title: "MENGAPA MEMILIH KAMI",
          PreDescriptionOne: "Mengapa",
          Description:
            "Di Antara Perusahaan Pengembangan Web & Aplikasi Terkemuka",
          Contains: [
            {
              Title: "UI/UX Pertama",
              Description:
                "Sebagai klien, UI dan UX sangat penting, karena dunia akan melihatnya. Jadi, kami akan selalu memberikan prioritas padanya. Jangan khawatir, kami memiliki ahli UI/UX.",
            },
            {
              Title: "Pemesanan Cepat dengan AstroFlaz",
              Description: `Kami menyadari bahwa efisiensi pemesanan dalam memilih tema dan mengumpulkan persyaratan penting bagi waktu Anda. Itulah mengapa kami membuat sistem pemesanan cepat "AstroFlaz".`,
            },
            {
              Title: "Dukungan Pemeliharaan Gratis",
              Description: `Perangkat lunak, terkadang ada masalah yang tidak terlihat dalam fase pengembangan. Jangan khawatir, kami menawarkan jaminan pemeliharaan selama 3-6 bulan setelah proyek selesai secara gratis.`,
            },
          ],
          RightSection: {
            PreTitle: "Mari Bangun",
            Title: "Aplikasi Impian Anda",
            LastTitle: "Menjadi Kenyataan",
            Description:
              "Jangan ragu untuk menghubungi kami, kami akan mengirimkan penawaran/quotation secepat mungkin",
            Button: "Minta Penawaran",
          },
        },
        OurWork: {
          Title: "PROYEK KAMI",
          Description: "Beberapa Proyek Kami",
        },
        Clients: {
          Title: "PELANGGAN PUAS KAMI",
          Description: "Beberapa Klien Kami",
        },
        LetsWork: {
          Title: "MARI BEKERJA BERSAMA",
          Description: "Kami Sangat Senang Mendengar Kebutuhanmu",
          Button: "Order Melalui Sistem AstroFlaz",
          Action: "Telepon Kami Sekarang",
        },
        Location: {
          Title: "LOKASI KAMI",
          Description: "Kantor",
        },
      },
      AboutUsContent: {
        Title: "Tentang Perusahaan",
        SectionOne: {
          Title: "Tentang Kami",
          Description:
            "Kami adalah sebuah perusahaan pengembangan perangkat lunak yang menawarkan solusi dalam digitalisasi bisnis untuk individu, UMKM, perusahaan, atau lembaga Anda. Codespace menawarkan pengembangan situs web, aplikasi seluler, dan layanan teknologi informasi lainnya dengan harga yang kompetitif dan terjangkau. Tujuan utama Codespace adalah menjadi mitra penyedia digitalisasi bisnis yang kompetitif dan terjangkau bagi pelanggan.",
          Value: {
            Title: "Nilai Utama",
            List: [
              "Menyediakan layanan digital terbaik yang fokus pada keinginan dan kepuasan pelanggan.",
              "Menyediakan layanan yang kompetitif dan terjangkau.",
              "Menggunakan teknologi terbaru sebagai referensi dalam layanan.",
              "Optimasi UI/UX dalam setiap proyek yang dibuat.",
              "Membangun kemitraan strategis dalam menyediakan layanan perbaikan setelah proyek selesai.",
            ],
          },
          Counter: {
            Title: ["Pelanggan Puas", "Tahun Pengalaman", "Proyek Selesai"],
          },
        },
        SectionTwo: {
          Title: "KAMI ADALAH CRAFTMANSHIP",
          Description: "Nilai Kami",
          List: [
            {
              Title: "Penggunaan Teknologi Terbaru",
              Description:
                "Kami melakukan penelitian untuk implemetasi teknologi terbaru.",
            },
            {
              Title: "Layanan Kompetitif & Terjangkau",
              Description:
                "Layanan yang kompetitif dan terjangkau dengan harga yang transparan.",
            },
            {
              Title: "Optimasi UI/UX",
              Description:
                "Optimasi antarmuka pengguna dan pengalaman pengguna dalam setiap proyek yang dibuat.",
            },
            {
              Title: "Membangun Kemitraan Strategis",
              Description:
                "Menyediakan layanan perbaikan setelah proyek selesai.",
            },
          ],
        },
      },
      OurTeamContent: {
        Title: "Tim Kami",
        SectionOne: {
          Title: "KAMI ADALAH ASTRO-C",
          Description: "Direktur Pelaksana Kami",
          List: [
            {
              Title: "CEO & CFO Perusahaan",
            },
            {
              Title: "CTO & COO Perusahaan",
            },
            {
              Title: "CMO Perusahaan",
            },
          ],
        },
        SectionTwo: {
          Title: "KAMI ADALAH ASTRO-DEV",
          Description: "Anggota Kami",
        },
      },
      ContactUsContent: {
        Title: "Hubungi",
        List: [
          {
            Title: "Telepon",
            Description: "Hari Kerja: Senin – Jumat, Jam 9 Pagi sampai 5 Sore.",
            Button: "Telepon Sekarang",
          },
          {
            Title: "Surel",
            Description:
              "Layanan pelanggan kami akan segera membalas dalam 24-Jam selama hari kerja.",
            Button: "Kirim Surel",
          },
        ],
      },
      Partner: {
        Title: "Anggota & Terdaftar Pada",
      },
      Footer: {
        LeftSection: {
          Title: "Perusahaan",
          List: ["Tentang", "Hubungi", "AstroDev Kami", "Blog Kami"],
        },
        CenterSection: {
          Title: "Hubungi Kami",
          List: [
            {
              Title: "Surel",
            },
            {
              Title: "Telepon",
            },
            {
              Title: "Alamat",
            },
          ],
        },
        RightSection: {
          Description:
            " merupakan software house yang menawarkan solusi dalam digitalisasi bisnis bagi individu, UMKM, perusahaan, maupun institusi.",
        },
      },
      Portofolio: {
        Detail: {
          Title: "Detail Proyek",
          AboutTheProject: "Tentang Proyek",
          AppFeature: "Fitur App",
          ApplicationFeature: "Fitur Aplikasi",
          Challenges: "Tantangan",
          Solutions: "Solusi",
        },
      },
    },
  },
  en: {
    translation: {
      Flag: "🇬🇧",
      FlagWithTitle: "🇬🇧 Translate",
      ToOther: "To 🇮🇩",
      Home: "Home",
      AboutUs: "About Us",
      OurTeam: "Our Team",
      Contact: "Contact Us",
      SendUsMessage: "Send Us Message",
      HomeContent: {
        // section 1
        Affordable: "Affordable",
        LatestTech: "Latest Tech",
        BestService: "Best Service",
        UIUXOptmization: "UI/UX Optimization",
        ShortVision: "Take your business to the next level",
        HashtagVision: "#RaiseYourBusiness #CodespaceIndonesia 🧑🏻‍🚀",
        LatestWorks: "Our Latest Works",
        // section 2
        WeAreSolution: "We Are Solution",
        Friends: "Friends",
        ForBuild: "for Build Web and Mobile Apps",
        WeProvide: "We provide an",
        WeProvide2: "affordable prices",
        WeProvide3:
          "to realize the digitalization of your business, but in another side we are trying to use",
        WeProvide4: "latest technology",
        WeProvide5: "in every project that created with",
        WeProvide6: "optimize User Interface and User Experience",
        WeProvide7: "or providing",
        WeProvide8: "best service",
        WeProvide9: "that focuses on customer satisfaction",
        Services: {
          CompanyProfile: {
            Title: "Company Profile",
            Description: `Web profile service is suitable for those of you who want a simple web without complex customization to display personal and company information.`,
          },
          WebApplication: {
            Title: "Web Apps",
            Description: `This service is perfect for those of you who need special features on a website. Don't worry, we use latest technology every development.`,
          },
          MobileApplication: {
            Title: "Mobile Apps",
            Description: `This option is perfect for those of you who want your application to be accessible by mobile using the functions that are on a mobile device.`,
          },
          ITConsultant: {
            Title: "IT CONSULTANT SERVICE",
            Description: `Discuss with us everything that is in your head regarding your
            business ideas, goals and aspirations in the future`,
            Button: "Get in touch",
          },
          Maintenance: {
            Title: "Maintenance Service",
            Description: `We provide maintenance or adding new minor feature while needed`,
            OptionOne: "AFTER SALES",
            OptionTwo: "OTHER VENDOR",
          },
          DigitalMarketing: {
            Title: "Digital Marketing",
            Description: `Codespace is preparing something awesome for astro people...`,
            OptionOne: "COMING SOON",
          },
        },
        WhyChooseUs: {
          Title: "WHY CHOOSE US",
          PreDescriptionOne: "Why",
          Description: "Among The Leading Web & App Development Companies",
          Contains: [
            {
              Title: "UI/UX First",
              Description:
                "As a client UI and UX is very important, because the world will see. So, we will always prioritize it. Don't worry, We have UI/UX experts.",
            },
            {
              Title: "Fast Order With AstroFlaz",
              Description: `We recognize that order efficiencies choosing a theme and gather requirement are important to your time. That's why we created a quick order system "AstroFlaz".`,
            },
            {
              Title: "Free Maintenance Support",
              Description: `Software, sometimes there are problems that are not visible in the development phase. Do't worry we offer 3-6 months maintenance guarantee after project finished for free.`,
            },
          ],
          RightSection: {
            PreTitle: "Let's Build Your",
            Title: "Dream App",
            LastTitle: "Come True",
            Description:
              "Do not hesitate to contact us, we will send you an offer/quotation as soon as possible",
            Button: "Request A Quote",
          },
        },
        OurWork: {
          Title: "OUR WORK",
          Description: "Some of Our Projects",
        },
        Clients: {
          Title: "OUR HAPPY CUSTOMERS",
          Description: "Some of our Clients",
        },
        LetsWork: {
          Title: "LET'S WORK TOGETHER",
          Description: "We Love to Listen to Your Requirements",
          Button: "AstroFlaz Order System",
          Action: "Call us now",
        },
        Location: {
          Title: "OUR LOCATIONS",
          Description: "The Office",
        },
      },
      AboutUsContent: {
        Title: "About Company",
        SectionOne: {
          Title: "About Us",
          Description:
            "We are a software house that offers solutions in business digitization for your individual, UMKM, company, or institution. Codespace offers website development, mobile applications and other information technology services at competitive and affordable prices. The main purpose of Codespace is to become a competitive and affordable business digitalization provider partner for customers.",
          Value: {
            Title: "Main Value",
            List: [
              "Providing the best digital services that focus on customer desires and satisfaction.",
              "Provide competitive and affordable services.",
              "Using the latest technology as a reference in service.",
              "UI/UX optimization in every project created.",
              "Establish strategic partnerships in providing repair services after the project is completed.",
            ],
          },
          Counter: {
            Title: ["Happy Clients", "Years Experience", "Projects Done"],
          },
        },
        SectionTwo: {
          Title: "WE ARE CRAFTMANSHIP",
          Description: "Our Value",
          List: [
            {
              Title: "Research & Using Latest Technology",
              Description:
                "We always research for app requirement fit and using the latest technology.",
            },
            {
              Title: "Competitive & Affordable Services",
              Description:
                "Provide competitive and affordable services with transparance price.",
            },
            {
              Title: "UI/UX Optimization Centric",
              Description:
                "User interface and user experience optimization in every project created.",
            },
            {
              Title: "Establish Strategic Partnerships",
              Description:
                "Providing repair services after the project is completed.",
            },
          ],
        },
      },
      OurTeamContent: {
        Title: "Our Team",
        SectionOne: {
          Title: "WE ARE ASTRO-C",
          Description: "Our Managing Directors",
          List: [
            {
              Title: "Company CEO & CFO",
            },
            {
              Title: "Company CTO & COO",
            },
            {
              Title: "Company CMO",
            },
          ],
        },
        SectionTwo: {
          Title: "WE ARE ASTRO-DEV",
          Description: "Our Team Members",
        },
      },
      ContactUsContent: {
        Title: "Contact",
        List: [
          {
            Title: "Call",
            Description: "Bussiness hours: Monday – Friday, 9 AM to 5 PM.",
            Button: "Call Now",
          },
          {
            Title: "Email",
            Description:
              "Our client support will get back to in 24-H during standard business hours.",
            Button: "Send Email",
          },
        ],
      },
      Partner: {
        Title: "Member & Listed On",
      },
      Footer: {
        LeftSection: {
          Title: "Company",
          List: ["About", "Contact", "Our AstroDev", "Our Blogs"],
        },
        CenterSection: {
          Title: "Contact Us",
          List: [
            {
              Title: "Email",
            },
            {
              Title: "Phone",
            },
            {
              Title: "Address",
            },
          ],
        },
        RightSection: {
          Description:
            " is a software house that offers solutions in business digitalization for individuals, MSMEs, companies and institutions.",
        },
      },
      Portofolio: {
        Detail: {
          Title: "Project Detail",
          AboutTheProject: "About The Project",
          AppFeature: "App Feature",
          ApplicationFeature: "Application Feature",
          Challenges: "Challenges",
          Solutions: "Solutions",
        },
      },
    },
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "id",
    lng: "id", // default
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
